import { useEffect, useState } from "react";
import {
  checkForAlreadyLogin,
  clearFirebaseResponse,
  login,
  register,
  sendResetPwEmail,
} from "../../actions/login.action";
import { FieldName, IValidation } from "../../models";
import Logo from "../../images/lms-logo.svg";
import {
  renderEmail,
  renderFirebaseResponse,
  renderForgetPassword,
  renderPassword,
  renderSignUpText,
  renderSubmit,
  validateLogin,
} from "./utils";
import "../../app/styles.css";
import "./styles.css";
import { useDispatch, useSelector } from "react-redux";
import { ReduxState } from "../../reducers";
import dispatch from "../../middleware";
import { isEmail } from "../../utils";

export interface IFirebaseResponse {
  type: string;
  response: {
    code: string;
    message: string;
  };
}

export interface ILoginFields {
  email: string;
  password: string;
  reEnteredPw: string;
}

function Login() {
  const storeDispatch = useDispatch();
  const urlParams = new URLSearchParams(
    `?${window.location.href.split("?")[1]}`
  );
  const [userInput, setUserInput] = useState<ILoginFields>({
    email: "",
    password: "",
    reEnteredPw: "",
  });
  const loading: boolean = useSelector(
    (state: ReduxState) => state.login.loading
  );
  const [isSignIn, updateIsSignIn] = useState<boolean>(true);
  const [error, setError] = useState({ type: "", msg: "" });
  const firebaseResponse: IFirebaseResponse = useSelector(
    (state: ReduxState) => state.login.firebaseResponse
  );
  function handleChange(fieldName: string, value: string | number) {
    setUserInput((prevState) => {
      return {
        ...prevState,
        [fieldName]: String(value),
      };
    });
    setError({ type: "", msg: "" });
    if (firebaseResponse.type !== "")
      dispatch(storeDispatch, clearFirebaseResponse());
  }
  function handleSubmit(e: any) {
    e.preventDefault();
    const validation: IValidation = validateLogin(
      userInput.email,
      userInput.password,
      userInput.reEnteredPw,
      isSignIn
    );
    if (validation.status) {
      if (isSignIn) {
        login(userInput.email, userInput.password, storeDispatch);
      } else {
        register(userInput.email, userInput.password, storeDispatch);
      }
    }
    setError({ type: validation.type, msg: validation.msg });
  }
  function handleForgotPassword() {
    if (isEmail(userInput.email)) {
      sendResetPwEmail(userInput.email, storeDispatch);
    } else {
      setError({ type: FieldName.EMAIL, msg: "Please enter your email" });
    }
  }
  useEffect(() => {
    if (firebaseResponse.type !== "") {
      dispatch(storeDispatch, clearFirebaseResponse());
    }
  }, [isSignIn]);
  useEffect(() => {
    checkForAlreadyLogin(storeDispatch);
  }, []);
  return (
    <div className="auth-wrapper">
      <div className="auth-card">
        <form>
          <div className="text-center">
            <img src={Logo} alt="Logo" className="logo-login mb-md-5 mb-4" />
          </div>

          <h5 className="subheading fs-14 mb-4 text-center">
            {isSignIn ? "Sign in" : "Sign up"}
          </h5>
          {renderEmail(
            userInput.email,
            handleChange,
            error.type === FieldName.EMAIL ? error.msg : ""
          )}
          {renderPassword(
            userInput.password,
            handleChange,
            "Password",
            FieldName.PASSWORD,
            error.type === FieldName.PASSWORD ? error.msg : ""
          )}
          {!isSignIn &&
            renderPassword(
              userInput.reEnteredPw,
              handleChange,
              "Re-enter password",
              FieldName.RE_ENTER_PW,
              error.type === FieldName.RE_ENTER_PW ? error.msg : ""
            )}
          {firebaseResponse.type !== "" &&
            renderFirebaseResponse(firebaseResponse.response.message, isSignIn)}
          {renderSubmit(handleSubmit, isSignIn, loading)}
          {isSignIn && <div>{renderForgetPassword()}</div>}
          <div className="mt-md-4 mt-2">
            {renderSignUpText(updateIsSignIn, isSignIn)}
          </div>
        </form>
      </div>
    </div>
  );
}
export default Login;
