import { LoanSearchDto, LoanSearchFilterDto } from "../dto";
import { UpdateWCTLApplication } from "../dto/enterprise.dto";
import { CreateInvoiceDto } from "../dto/invoice.dto";
import { MiddlewareAction } from "../middleware";
import {
  AddLoanPayment,
  AddLoanWithdrawal,
  AddTermLoanPayment,
  addWCTLoanDisbursal,
  AddWCTLRepayment,
  AddWCTLTdsCredit,
  LoanDisbursalPayload,
  LoanType,
} from "../models";

export enum Actions {
  REQUEST_GET_LOAN_BY_ID = "REQUEST_GET_LOAN_BY_ID",
  REQUEST_GET_COMMERCIAL_LOAN_BY_ID = "REQUEST_GET_COMMERCIAL_LOAN_BY_ID",
  REQUEST_GET_PAGINATED_LOANS = "REQUEST_GET_PAGINATED_LOANS",
  REQUEST_GET_LOANS_BY_USER_ID = "REQUEST_GET_LOANS_BY_USER_ID",
  REQUEST_GET_LOANS_BY_ENTERPRISE_ID = "REQUEST_GET_LOANS_BY_ENTERPRISE_ID",
  REQUEST_ADD_LOAN_PAYMENT = "REQUEST_ADD_LOAN_PAYMENT",
  REQUEST_ADD_TERM_LOAN_PAYMENT = "REQUEST_ADD_TERM_LOAN_PAYMENT",
  REQUEST_ADD_LOAN_WITHDRAWAL = "REQUEST_ADD_LOAN_WITHDRAWAL",
  REQUEST_GET_REPAYMENT_SCHEDULE_BY_LOAN_ID = "REQUEST_GET_REPAYMENT_SCHEDULE_BY_LOAN_ID",
  REQUEST_GET_LOAN_BY_APPLICATION_ID = "REQUEST_GET_LOAN_BY_APPLICATION_ID",
  REQUEST_GET_LOAN_COUNT = "REQUEST_GET_LOAN_COUNT",
  REQUEST_GET_LOAN_PAGINATION_COUNT = "REQUEST_GET_LOAN_PAGINATION_COUNT",
  REQUEST_GET_LOAN_SEARCH_FILTERS = "REQUEST_GET_LOAN_SEARCH_FILTERS",
  REQUEST_GET_LOANS_BY_SEARCH = "REQUEST_GET_LOANS_BY_SEARCH",
  REQUEST_GET_COMMERICIAL_LOANS_BY_SEARCH = "REQUEST_GET_COMMERICIAL_LOANS_BY_SEARCH",
  REQUEST_ADD_LOAN_DISBURSAL = "REQUEST_ADD_LOAN_DISBURSAL",
  REQUEST_GET_TRANSACTIONS_BY_LOAN_ID = "REQUEST_GET_TRANSACTIONS_BY_LOAN_ID",
  REQUEST_GET_COMMERCIAL_LOAN_STATEMENT_BY_LOAN_ID = "REQUEST_GET_COMMERCIAL_LOAN_STATEMENT_BY_LOAN_ID",
  REQUEST_GET_COMMERCIAL_OD_TRANSACTION_STATEMENT = "REQUEST_GET_COMMERCIAL_OD_TRANSACTION_STATEMENT",
  REQUEST_ADD_COMMERCIAL_OD_TRANSACTION_CHARGE_INTEREST = "REQUEST_ADD_COMMERCIAL_OD_TRANSACTION_CHARGE_INTEREST",
  REQUEST_ADD_COMMERCIAL_OD_TRANSACTION_WITHDRAWAL = "REQUEST_ADD_COMMERCIAL_OD_TRANSACTION_WITHDRAWAL",
  REQUEST_ADD_COMMERCIAL_OD_TRANSACTION_PAYMENT = "REQUEST_ADD_COMMERCIAL_OD_TRANSACTION_PAYMENT",
  REQUEST_ADD_COMMERCIAL_OD_TRANSACTION_TDS_CREDIT = "REQUEST_ADD_COMMERCIAL_OD_TRANSACTION_TDS_CREDIT",
  REQUEST_CREATE_COMMERCIAL_OD_INVOICE_ENTRY = "REQUEST_CREATE_COMMERCIAL_OD_INVOICE_ENTRY",
  REQUEST_TERM_LOAN_REPAYMENT_BY_LOAN_ID = "REQUEST_TERM_LOAN_REPAYMENT_BY_LOAN_ID",
  REQUEST_GET_TERM_LOAN_LEDGER_INFO_BY_LOAN_ID = "REQUEST_GET_TERM_LOAN_LEDGER_INFO_BY_LOAN_ID",
  REQUEST_GET_RETAIL_OD_LEDGER_INFO_BY_LOAN_ID = "REQUEST_GET_RETAIL_OD_LEDGER_INFO_BY_LOAN_ID",
  REQUEST_UPDATE_WCTL_APPLICATION = "REQUEST_UPDATE_WCTL_APPLICATION",
  REQUEST_GET_WORKING_CAPITAL_TERM_LOAN_BY_SEARCH = "REQUEST_GET_WORKING_CAPITAL_TERM_LOAN_BY_SEARCH",
  REQUEST_GET_WCTL_LOAN_DETAILS = "REQUEST_GET_WCTL_LOAN_DETAILS",
  REQUEST_WCTL_REPAYMENT_SCHEDULE = "REQUEST_WCTL_REPAYMENT_SCHEDULE",
  REQUEST_ADD_WCTL_DISBURSAL = "REQUEST_ADD_WCTL_DISBURSAL",
  REQUEST_ADD_WCTL_TDS_CREDIT = "REQUEST_ADD_WCTL_TDS_CREDIT",
  REQUEST_ADD_WCTL_REPAYMENT = "REQUEST_ADD_WCTL_REPAYMENT",
}

export function getTermLoanRepaymentByLoanId(loanId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_TERM_LOAN_REPAYMENT_BY_LOAN_ID,
    type: "GET",
    url: `/v1/loans/term-loans/${loanId}/repayments`,
  };
}

export function getTermLoanLedgerInfoByLoanId(
  loanId: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_TERM_LOAN_LEDGER_INFO_BY_LOAN_ID,
    type: "GET",
    url: `/v1/loans/term-loans/${loanId}/ledger-info`,
  };
}

export function getLoanById(loanId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_LOAN_BY_ID,
    type: "GET",
    url: `/loan?loanId=${loanId}`,
  };
}

export function getCommercialLoanById(loanId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_COMMERCIAL_LOAN_BY_ID,
    type: "GET",
    url: `/v1/loans/commercial-ods/${loanId}`,
  };
}

export function getCommercialODLoanStatementByLoanId(
  loanId: string,
  asOf: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_COMMERCIAL_LOAN_STATEMENT_BY_LOAN_ID,
    type: "GET",
    url: `/v1/loans/commercial-ods/${loanId}/statement?asOf=${asOf}`,
  };
}

export function getCommercialODTransactionStatementByLoanId(
  loanId: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_COMMERCIAL_OD_TRANSACTION_STATEMENT,
    type: "GET",
    url: `/v1/loans/commercial-ods/${loanId}/transactions`,
  };
}

export function addCommercialODTransactionChargeInterest(
  loanId: string,
  onDate: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_ADD_COMMERCIAL_OD_TRANSACTION_CHARGE_INTEREST,
    type: "POST",
    url: `/v1/loans/commercial-ods/${loanId}/interest-charge`,
    body: { onDate },
  };
}

export function addCommercialODTransactionWithdrawal(
  loanId: string,
  body: {
    amount: number;
    onDate: string;
  }
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_ADD_COMMERCIAL_OD_TRANSACTION_WITHDRAWAL,
    type: "POST",
    url: `/v1/loans/commercial-ods/${loanId}/withdrawal`,
    body,
  };
}

export function addCommercialODTransactionPayment(
  loanId: string,
  body: {
    amount: number;
    paymentRefId: string;
    source: string;
    paidAt: string;
  }
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_ADD_COMMERCIAL_OD_TRANSACTION_PAYMENT,
    type: "POST",
    url: `/v1/loans/commercial-ods/${loanId}/repayment`,
    body,
  };
}

export function addCommercialODTransactionTDSCredit(
  loanId: string,
  body: {
    amount: number;
    paymentRefId: string;
    source: string;
    paidAt: string;
  }
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_ADD_COMMERCIAL_OD_TRANSACTION_TDS_CREDIT,
    type: "POST",
    url: `/v1/loans/commercial-ods/${loanId}/tds-credit`,
    body,
  };
}

export function createCommercialODInvoiceEntry(
  loanId: string,
  body: CreateInvoiceDto
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_CREATE_COMMERCIAL_OD_INVOICE_ENTRY,
    type: "POST",
    url: `/v1/loans/commercial-ods/${loanId}/invoice`,
    body,
  };
}

export function getPaginatedLoans(
  pageNo: number,
  numberOfEntitiesPerPage: number,
  loanType: LoanType
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_PAGINATED_LOANS,
    type: "POST",
    url: "/loan/paginated-loans",
    body: { pageNo, numberOfEntitiesPerPage, loanType },
  };
}

export function getLoansByUserId(userId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_LOANS_BY_USER_ID,
    type: "GET",
    url: `/loan/user-loans?userId=${userId}`,
  };
}

export function getLoansByEnterpriseId(enterpriseId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_LOANS_BY_ENTERPRISE_ID,
    type: "GET",
    url: "/loan/enterprise-loans",
    queryParams: { enterpriseId },
  };
}

export function addTermLoanPayment(
  addTermLoanPaymentObj: AddTermLoanPayment,
  loanId: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_ADD_TERM_LOAN_PAYMENT,
    type: "POST",
    url: `/v1/loans/term-loans/${loanId}/credits`,
    body: addTermLoanPaymentObj,
  };
}

// We are going to deprecate this '/loan/add-payment' endpoint I'm not removing this from the code because same endpoint we are using for both the loan Term Loan & Retail Od Loans to mark the repayment. For now we use '/loan/add-payment' only for Retail Od Loans.
export function addLoanPayment(
  addPaymentObj: AddLoanPayment
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_ADD_LOAN_PAYMENT,
    type: "POST",
    url: "/loan/add-payment",
    body: addPaymentObj,
  };
}

export function addLoanWithdrawal(
  addWithdrawalObj: AddLoanWithdrawal
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_ADD_LOAN_WITHDRAWAL,
    type: "POST",
    url: "/loan/add-withdrawal",
    body: addWithdrawalObj,
  };
}

export function getRepaymentScheduleByLoanId(loanId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_REPAYMENT_SCHEDULE_BY_LOAN_ID,
    type: "GET",
    url: `/loan/repayment-schedule?loanId=${loanId}`,
  };
}

export function getLoanByApplicationId(appId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_LOAN_BY_APPLICATION_ID,
    type: "GET",
    url: `/loan/loan-by-appId?appId=${appId}`,
  };
}

export function getLoanCount(): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_LOAN_COUNT,
    type: "GET",
    url: "/loan/loans-count",
  };
}

export function getLoanSearchFilters(loanType: LoanType): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_LOAN_SEARCH_FILTERS,
    type: "GET",
    url: "/loan/search-filters",
    queryParams: { loanType },
    metadata: { loanType },
  };
}

export function getLoansBySearch({
  pageNo,
  numberOfEntitiesPerPage,
  loanType,
  searchText,
  status,
}: LoanSearchDto): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_LOANS_BY_SEARCH,
    type: "GET",
    url: "/loan/search",
    queryParams: {
      pageNo,
      numberOfEntitiesPerPage,
      loanType,
      searchText,
      status,
    },
  };
}

export function getWCTLoansBySearch({
  pageNo,
  numberOfEntitiesPerPage,
  enterpriseId,
  status,
}: LoanSearchDto): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_WORKING_CAPITAL_TERM_LOAN_BY_SEARCH,
    type: "GET",
    url: `/v1/loans/working-capital-term-loans`,
    queryParams: {
      status,
      pageSize: numberOfEntitiesPerPage,
      pageNumber: pageNo,
      enterpriseId,
    },
  };
}

export function getWCTLoanDetails(loanId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_WCTL_LOAN_DETAILS,
    type: "GET",
    url: `/v1/loans/working-capital-term-loans/${loanId}`,
  };
}

export function getWCTLRepaymentSchedule(loanId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_WCTL_REPAYMENT_SCHEDULE,
    type: "GET",
    url: `/v1/loans/working-capital-term-loans/${loanId}/repayment-schedule`,
  };
}

export function getCommercialODLoansBySearch({
  pageNo,
  numberOfEntitiesPerPage,
  status,
}: LoanSearchDto): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_COMMERICIAL_LOANS_BY_SEARCH,
    type: "GET",
    url: "/v1/loans/commercial-ods",
    queryParams: {
      status,
      pageSize: numberOfEntitiesPerPage,
      pageNumber: pageNo,
    },
  };
}

export function getPaginationLoanCount(
  payload: LoanSearchFilterDto
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_LOAN_PAGINATION_COUNT,
    type: "GET",
    url: "/loan/pagination-count",
    queryParams: { ...payload },
  };
}

export function addLoanDisbursal(
  payload: LoanDisbursalPayload
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_ADD_LOAN_DISBURSAL,
    type: "POST",
    url: "/loan/disburse-loan",
    body: payload,
  };
}

export function getTransactionsByLoanId(loanId: string): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_TRANSACTIONS_BY_LOAN_ID,
    type: "GET",
    url: "/loan/loan-transactions",
    queryParams: { loanId },
  };
}

export function getRetailOdLedgerInfoByLoanId(
  loanId: string
): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_GET_RETAIL_OD_LEDGER_INFO_BY_LOAN_ID,
    type: "GET",
    url: `/v1/loans/retail-ods/${loanId}/ledger-info`,
  };
}

export function updateWCTLApplication({
  appId,
  body,
}: {
  appId: string;
  body: UpdateWCTLApplication;
}): MiddlewareAction {
  return {
    actionName: Actions.REQUEST_UPDATE_WCTL_APPLICATION,
    type: "PATCH",
    url: `/v1/applications/working-capital-term-loans/${appId}`,
    body,
  };
}
export function updateWCTLoanDisbursal(cxt: {
  loanId: string;
  body: addWCTLoanDisbursal;
}): MiddlewareAction {
  const { loanId, body } = cxt;
  return {
    actionName: Actions.REQUEST_ADD_WCTL_DISBURSAL,
    type: "PATCH",
    url: `/v1/loans/working-capital-term-loans/${loanId}/disbursal-details`,
    body,
  };
}

export function addWCTLTdsCredit(cxt: {
  loanId: string;
  body: AddWCTLTdsCredit;
}): MiddlewareAction {
  const { loanId, body } = cxt;
  return {
    actionName: Actions.REQUEST_ADD_WCTL_TDS_CREDIT,
    type: "POST",
    url: `/v1/loans/working-capital-term-loans/${loanId}/tds-credit`,
    body,
  };
}

export function addWCTLRepayment(cxt: {
  loanId: string;
  body: AddWCTLRepayment;
}): MiddlewareAction {
  const { loanId, body } = cxt;
  return {
    actionName: Actions.REQUEST_ADD_WCTL_REPAYMENT,
    type: "POST",
    url: `/v1/loans/working-capital-term-loans/${loanId}/repayment`,
    body,
  };
}
