import {
  CommercialLoanDetails,
  RepaymentScheduleDetails,
  RetailODDetails,
  RetailOdLedgerInfoDto,
  TermLoanDetails,
  TermLoanLedgerInfoDto,
} from "../../dto";
import { LoanType } from "../../models";
import { dateToDDMMYYYY } from "../../utils";

export function refactorCommercialLoanData(
  commercialLoanDetails: CommercialLoanDetails | null
) {
  if (!commercialLoanDetails) return;
  const tempVal: Record<string, any> = { ...commercialLoanDetails };
  tempVal.loanType = tempVal.borrowerInfo.borrowerType;
  return tempVal;
}

export function refactorLoanData(
  loanDetails: TermLoanDetails | RetailODDetails | null,
  termLoanLedgerInfo: TermLoanLedgerInfoDto | null,
  retailOdLedgerInfo: RetailOdLedgerInfoDto | null
) {
  if (!loanDetails) return;

  const tempVal: Record<string, any> = {
    ...(loanDetails.loanType === "TERM_LOAN" && {
      ...loanDetails,
      ...termLoanLedgerInfo,
    }),
    ...(loanDetails.loanType === "RETAIL_OD" && {
      ...loanDetails,
      ...retailOdLedgerInfo,
    }),
  };

  tempVal.disbursedOn =
    tempVal.disbursalDetails?.date &&
    dateToDDMMYYYY(tempVal.disbursalDetails?.date);
  tempVal.bankAccountNo = tempVal.disbursalDetails?.bankAccountNo;
  tempVal.ifscCode = tempVal.disbursalDetails?.ifscCode;
  tempVal.transactionRefNo = tempVal.disbursalDetails?.transactionRefNo;
  return tempVal;
}

export function repaymentScheduleSortByEmiNo(
  repaymentSchedule: RepaymentScheduleDetails[],
  type: "ASC" | "DESC" = "ASC"
) {
  return repaymentSchedule.sort((a, b) =>
    type === "ASC" ? a.emiNumber - b.emiNumber : b.emiNumber - a.emiNumber
  );
}

export function isNumberInRange(
  value: number,
  range: { min: number; max: number }
) {
  return value >= range.min && value <= range.max;
}

export function validateAddTermLoanPaymentFields(fieldData: any) {
  if (!fieldData?.amount) throw new Error("Enter amount");
  if (!fieldData?.transactionRefNo) throw new Error("Enter Transaction Ref no");
  if (!fieldData?.paidAt) throw new Error("Enter paid date");
  if (!fieldData?.medium) throw new Error("Select transaction medium");
}

export function validateAddPaymentFields(
  loanType: LoanType,
  fieldData: any,
  repaymentSchedules?: RepaymentScheduleDetails[]
) {
  if (loanType === "TERM_LOAN" && !fieldData?.emiNo)
    throw new Error("Enter EMI number");
  if (!fieldData?.amount) throw new Error("Enter amount");
  if (!fieldData?.transactionRefNo) throw new Error("Enter Transaction Ref no");
  if (!fieldData?.paidDate) throw new Error("Enter paid date");
  if (loanType === "TERM_LOAN" && repaymentSchedules) {
    if (parseInt(fieldData?.emiNo) > repaymentSchedules.length) {
      throw new Error(
        `EMI number shouldn't be greater than ${repaymentSchedules.length}`
      );
    }
    // TODO: Move this logic to somewhere else
    const scheduleIndex = repaymentSchedules.findIndex(
      ({ emiNumber }) => emiNumber === parseInt(fieldData?.emiNo)
    );
    if (scheduleIndex < 0) return;
    const selectedSchedule = repaymentSchedules[scheduleIndex];
    const emiAmountRange = {
      min: selectedSchedule.principal + selectedSchedule.interest - 10,
      max: selectedSchedule.principal + selectedSchedule.interest + 10,
    };
    if (!isNumberInRange(parseInt(fieldData.amount), emiAmountRange)) {
      throw new Error(
        `EMI amount for EMI number ${
          selectedSchedule.emiNumber
        } should be within ${emiAmountRange.min.toFixed()} to ${emiAmountRange.max.toFixed()}`
      );
    }
  }
}

export function validateAddWithdrawalFields(fieldData: any) {
  if (!fieldData?.amount) throw new Error("Enter amount");
  if (!fieldData?.transactionRefNo) throw new Error("Enter Transaction Ref no");
  if (!fieldData?.withdrawalAt) throw new Error("Enter withdrawal date");
  if (!fieldData?.txnFeeInAmount) throw new Error("Enter Txn Fee amount");
}

export function validateAddChargeInterestFields(fieldData: any) {
  if (!fieldData?.onDate) throw new Error("Enter Charge Interest Date");
}

export function validateAddTDSCreditAndRepaymentFields(fieldData: any) {
  if (!fieldData?.amount) throw new Error("Enter Amount");
  if (!fieldData?.paymentRefId) throw new Error("Enter Payment Ref ID");
  if (!fieldData?.source) throw new Error("Enter Source");
  if (!fieldData?.paidAt) throw new Error("Enter Paid At");
}

export function vlidateAddDrawdownFields(fieldData: any) {
  if (!fieldData?.utr) throw new Error("Enter Payment Ref ID");
  if (!fieldData?.amount) throw new Error("Enter Amount");
  if (!fieldData?.onDate) throw new Error("Enter Drawdown On Date");
}

export function calculateGST(
  chargeValue: number,
  gstRateForLoanProcessingCharge: number
): number {
  return chargeValue * gstRateForLoanProcessingCharge;
}
